<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <p>Please enter your login credentials</p>
        </div>
      </div>
      <b-alert variant="danger" show>
        <div v-if="error" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ error }}</small>
          </b-card-text>
        </div>
      </b-alert>

      <validation-observer ref="loginForm" #default="{invalid}">
        <b-form @submit.prevent="login">
          <!--                username -->
          <b-form-group label="Email / Phone" label-for="login-username">
            <validation-provider #default="{ errors }" name="Email / Phone" vid="username" rules="required">
              <div class="exxtra-input">
                <span class="leading">
                  <RegistrationMail />
                </span>
                <input id="login-username" v-model="username" autocomplete="false" placeholder="Enter your email address or phone">
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--                password -->
          <b-form-group label="Password" label-for="login-password">
            <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
              <div class="exxtra-input">
                <span class="leading">
                  <PasswordLock />
                </span>
                <input id="login-password" v-model="password" autocomplete="false" :type="passwordFieldType" placeholder="*****">
                <span class="trailing" @click="togglePasswordVisibility">
                    <feather-icon :icon="passwordToggleIcon" />
                  </span>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="d-flex justify-content-end">
              <b-link :to="{ name:'auth-forgot-password' }">
                <small class="pt-2">Forgot Password?</small>
              </b-link>
            </div>
          </b-form-group>

          <div>
            <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid">
              Sign in
            </b-button>
          </div>
        </b-form>
      </validation-observer>

      <div>
        <div class="mt-2 text-center">
          <p>
            Don't have an account ? <router-link :to="{ name: 'auth-register' }" class="text-dark">
            Sign Up</router-link> 
          </p>

          <p class="text-primary" style="font-size: 14px;">
            <router-link to="/" class="text-primary">
              In a hurry? Click here to give
            </router-link> 
          </p>
        </div>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
// import store from '@/store/index'
import {
  MUTATE_USER_ABILITIES
} from "@/store/config/mutation-keys"

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import PasswordLock from "@/assets/svg/PasswordLock.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";

import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import {
  get
} from "lodash"
/* eslint-disable global-require */
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AuthWrapper,
    RegistrationMail,
    PasswordLock,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    BottomDesign,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: '',
      remember_me: '',
      password: '',
      username: '',
      passwordFieldType: "password",
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true
        this.error = "";

        const success = await this.$refs.loginForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }
        const response = await this.useJwt()
          .authService
          .login({
            username: this.username,
            password: this.password,
          });

        const response_data = response.data.data;
        // console.log('response_data', response_data)
        const {
          mfa_auth_required
        } = response_data;

        // check if user has mfa enabled
        if (mfa_auth_required) {
          // save access token from payload into app jwt auth service
          // retrieve saved user preferred mfa method to proceed on mfa type
          const {
            mfa_access_token,
            mfa_method
          } = response_data;
          this.useJwt().mfaService.setMfaToken(mfa_access_token);

          // check if mfa method is app
          // proceed to authentication app QR code screen if mfa method is app
          // proceed to verify sent mfa otp if mfa method is email/sms
          this.$router.push({
            name: "security-mfa-authentication",
            params: {
              mfa_method
            }
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful log in',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Kindly enter your otp'
              },
            });
          })
          .catch(() => {});
        } else {
          const { user, settings, abilities, access_token } = response_data;

          this.useJwt().authService.setToken(access_token);
          
          this.$ability.update(abilities)
          this.$store.commit(`auth/${MUTATE_USER_ABILITIES}`, abilities)

          const days_before_password_expire_reminder = this.getValueFromSource(settings, "security_settings.days_before_password_expire_reminder", 0)
          const next_route = user.days_before_password_expire <= days_before_password_expire_reminder ? "security-password-expiry" : "member-home"

          await this.$router.replace({
            name: next_route
          })
          .catch(() => {});

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${user.first_name || user.last_name}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${user.user_type}.`,
            },
          });
        }
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
      } finally {
        this.loading = false;
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

sup {
  font-size: 1rem;
}
</style>
